import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
const CookieConfirm = ({ language }) => {
    return (
        <div className="cookie_cont">
            <CookieConsent
                style={{
                    background: "rgb(74 90 96)",
                    position: "sticky !important",
                    bottom: "0",
                    width: "100%",
                    zIndex: "99999",
                    height: "100%",
                }}
                buttonStyle={{ background: "#79ba35", color: "#ffffff" }}
                buttonText={language == "en" ? "Accept" : "Приемам"}
                location="bottom"
                containerClasses="cookie_consent"
            >
                {" "}
                {language == "en" ? (
                    <span>
                        {" "}
                        We use cookies to ensure that we provide you with the
                        best experience on our website. More information can be
                        found in our{" "}
                        <Link to="info/cookie-policy" className="cookie_link">
                            Cookie Policy
                        </Link>{" "}
                        and{" "}
                        <Link to="info/privacy-policy" className="cookie_link">
                            Privacy Policy
                        </Link>{" "}
                        .
                    </span>
                ) : (
                    <span>
                        {" "}
                        Използваме бисквитки, за да гарантираме, че ви
                        предоставяме най-доброто изживяване на нашия уебсайт.
                        Повече информация можете да намерите на{" "}
                        <Link
                            to="info/politika-za-poveritelnost"
                            className="cookie_link"
                        >
                            Политика за поверителност
                        </Link>{" "}
                        <Link
                            to="info/politika-za-biskvitki"
                            className="cookie_link"
                        >
                            Политика за бисквитките
                        </Link>{" "}
                      
                        .
                    </span>
                )}{" "}
            </CookieConsent>
        </div>
    );
};
export default CookieConfirm;
