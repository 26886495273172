import logo from "./logo.svg";
import "./App.css";

import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import { useEffect, useState } from "react";

import Spiner from "./Components/Spiner";
import * as baseService from "./services/baseService";
import LanguageContext from "./Contexts/LanguageContext";
import FacebookChat from "./Components/Chat/FacebookChat";
import EUProgram from "./Components/EUProgram";
import CookieConfirm from "./Components/CookieConsent/CookieConfirm";
import Categories from "./Components/Categories/Categories";

const Header = lazy(() => import("./Components/Header/Header"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const Home = lazy(() => import("./Components/home/Home"));
const About = lazy(() => import("./Components/About/About"));
const Contacts = lazy(() => import("./Components/Contacts/Contacts"));
const Team = lazy(() => import("./Components/Team/Team"));
const InfoPage = lazy(() => import("./Components/InfoPage/InfoPage"));

function App() {
  const defaultLanguage = navigator.language.includes("bg") ? "bg" : "en";
  const [language, setLanguage] = useState("bg");
  const [pages, setPages] = useState(null);

  useEffect(() => {
    baseService
      .getAllPages()
      .then((result) => {
        setPages(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="App">
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <FacebookChat />
        <Suspense fallback={<Spiner />}>
          <Header pages={pages} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/team" element={<Team />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/info/:slug" element={<InfoPage />} />
          </Routes>
          <EUProgram />
          <Footer pages={pages} />
          <CookieConfirm language={language} />
        </Suspense>
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
