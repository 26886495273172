import { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../Contexts/LanguageContext";



const EUProgram = () => {
    const {language} = useContext(LanguageContext)
    return (
      <div className="eu container">
        <div className="logo_kempes_eu">
          {language === "en" ? (
            <p>
              The website of Kempes NB EOOD was created in accordance with
              project under Contract No. BG-RRP-3.005-7326-C01, financed under
              The program for economic transformation to the National Plan for
              recovery and sustainability under procedure BG-RRP-3.005
              "Decisions in the field of information and communication
              technologies and cybersecurity in small and medium enterprises.
            </p>
          ) : (
            <p>
              Интернет страницата на Кемпес НБ ЕООД е изработена в изпълнение на
              проект по Договор № BG-RRP-3.005-7326-C01, финансиран по
              Програмата за икономическа трансформация към Националния план за
              възстановяване и устойчивост по процедура BG-RRP-3.005 „Решения в
              областта на информационните и комуникационни технологии и
              киберсигурността в малките и средни предприятия.
            </p>
          )}
        </div>
        <div className="logo_eu">
          <Link to={"/info/finansirane-ot-evropeiskiia"}>
            <img src="/assets/img/eu-.jpg" alt="logo" />
          </Link>
        </div>
      </div>
    );
}
export default EUProgram;